const fetch = window.$globalUtil.axios

export const pickImage = ({ type }) => {
  return new Promise((resolve, reject) => {
    let input = document.querySelector('#imageUploader')
    if (!input) {
      input = document.createElement('input')
      input.setAttribute('id', 'imageUploader')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg,image/jpg,image/png')
      input.style = 'width:0;height:0;display:none;'
      document.body.appendChild(input)
      input.addEventListener('change', e => {
        if (e.target.files.length) {
          uploadImage({ file: e.target.files[0], type })
            .then(url => {
              resolve(url)
            })
            .finally(() => {
              input.remove()
            })
        } else {
          resolve()
          input.remove()
        }
      })
    }
    input.click()
  })
}

export const uploadImage = ({ file, type }) => {
  if (file?.type?.substring(0, 5) === 'image') {
    let formData = new FormData()
    formData.append('file', file)
    return fetch.post(`/boom-px-api-service/upload/image/${type}`, formData)
  }
}

export const getImageSize = url => {
  return new Promise((resolve, reject) => {
    if (!url) {
      resolve([100, 100])
    }
    let image = new Image()
    image.src = url
    if (image.complete) {
      resolve([image.width, image.height])
    }
    image.onload = () => {
      resolve([image.width, image.height])
    }
    image.onerror = err => {
      console.log('>>>>>>> image loade err', err)
      resolve([0, 0])
    }
  })
}
